$body-color: #F1EDED;

$separator-color: #dee2e6;

$title-color: #2F557C;

$primary-color: #2196F3;
$secondary-color: #329EF4;
$grey-color: #8e8e8e;

$tab-color: #E0F1FF;

$tab-active-color: $primary-color;

$footer-color: #2F557C;