input[type='checkbox'] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    &:focus{
        outline: none;
    }

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    &.only-checkbox{
        & + label:before {
            margin-right: 0px !important;
        }
    }

    // Box.
    & + label:before {
        content: '';
        border: 1px solid #626262;
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
    }

    // Box hover
    &:hover + label:before {
        outline: lightgrey solid 1px;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 4px;
        background: white;
        width: 6px;
        height: 10px;
        border: solid gray;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

input[type='radio'] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    &:focus{
        outline: none;
    }

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    &.only-checkbox{
        & + label:before {
            margin-right: 0px !important;
        }
    }

    // Box.
    & + label:before {
        content: '';
        border: 1px solid #626262;
        border-radius: 50%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
    }

    // Box hover
    &:hover + label:before {
        /*outline: lightgrey solid 1px;*/
        box-shadow: 0px 0px 0px 1px #626262;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 7px;
        width: 5px;
        height: 5px;
        border: solid #626262;
        border-radius: 50%;
    }
}


.button{
    width: 100%;
    background-color: $primary-color;
    line-height: 1;
    color: white;
    cursor: pointer;
    padding: .8rem 0;
    font-size: 1.25rem;
    border: none;
    border-radius: 0.25rem;
    text-align: center;

    transition: background-color 0.25s;

    &:hover{
        background-color: darken($primary-color, 8%);
    }

    &:active, &:focus{
        outline: none;
    }

    &:active{
        background-color: #68babd;
    }
    &.rounded{
        border-radius: 1.5rem !important;
    }
    &:disabled{

        background-color: $grey-color;
        cursor:default;
    }

}
#registration-app{


    .radios-as-buttons {
        display: inline-block;
        div {
            display: inline;
        }
        input[type='radio'] {
            & + label {
                color:#000;
            }
            & + label:before {
                border: 1px solid #000;
            }
            & + label:after {
                border: 1px solid #000;
            }
        }
    }
}
button.small{
    @extend .button;
    border-radius: 100%;
    border: none;
    width: 1rem;
    height: 1rem;
    font-size: 1.15rem;
    padding: 0;
    display: inline-block;
    line-height: 0.85rem;
    text-align: center;
}

.text{
    border-radius: 1.35rem;
    border: 1px solid rgb(169,169,169);

    padding: 0.15rem 0.45rem;

    &:focus{
        outline: none;
    }
}

.textarea{
    resize: none;

    min-width: 100%;
    min-height: 100px;
    max-height: 100px;
    overflow-y: auto;

    border-radius: 0.75rem;
    padding: .35rem 1rem;

    $placeholder : #ABABAB;

    &::-webkit-input-placeholder {
        font-weight: bold;
        color: $placeholder;
    }

    &:-moz-placeholder { /* Firefox 18- */
        font-weight: bold;
        color: $placeholder;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
        font-weight: bold;
        color: $placeholder;
    }

    &:-ms-input-placeholder {
        font-weight: bold;
        color: $placeholder;
    }

    &::placeholder {
        font-weight: bold;
        color: $placeholder;
    }
}

.input-inline{
    border: none;
    //border-bottom: 1px solid black;
    width: 1.5rem;
    text-align: center;
}

.table{
    width: 100%;
    table-layout: fixed;

    border: none;

    thead{
        color: white;

        border-collapse: collapse;

        th{
            background-color: $primary-color;
            text-align: center;
            border: none;
            border-right: 2px solid #97CEF9;
            border-bottom: 2px solid #97CEF9;
            border-collapse: collapse;

            &:last-of-type{
                border-right: none;
            }
        }
    }

    tbody{

        tr{
            td{
                text-align: center;
                border: 2px solid #F1EDED;
                border-top: none;
                vertical-align: middle;
                border-collapse: collapse;
                word-wrap:break-word;
            }
        }
    }
}

.table.modal-table{

    table-layout: auto;

    thead{

        th{
            border-right: 2px solid white;
            border-bottom: none;
        }
    }

    tbody{

        &:before {
            content: "-";
            display: block;
            line-height: 1em;
            color: transparent;
        }

    }
}

.table.tab-table{
    thead{
        th{
            position: sticky;
            z-index: 1;
            top: 0rem;

            text-align: center;
            vertical-align: middle;
            line-height: 1.5;
            border: none;

            &:after{
                content:'';
                position:absolute;
                left: 0;
                bottom: 0;
                width:100%;
                border-bottom: 2px solid #97CEF9;
            }

            &:before{
                content:'';
                position:absolute;
                left: -1px;
                bottom: 0;
                height:100%;
                border-right:2px solid #97CEF9;
            }

            &:nth-of-type(even){
                background-color: $primary-color;
            }

            &:nth-of-type(odd){
                background-color: $secondary-color;
            }
        }
    }
}

.table-responsive{
    display: table;
}

.radios-as-buttons {
    display: inline-block;
    div {
        display: inline;
    }
    input[type='radio'] {
        & + label {
            margin: 2px 2px;
            padding: 2px !important;
            border: 1px solid #BBBBBB;
        }
        &:checked + label:after {
            content: '';
            position: absolute;
            left: 9px;
            top: 9px;
            width: 5px;
            height: 5px;
            border: solid #626262;
            border-radius: 50%;
        }
    }
}


@media only screen and (max-width: 991px) {

    .radios-as-buttons {

        overflow-y: auto;
        border-bottom: 1px solid;
        padding-bottom: 5px;
    }
    .table-responsive  { display: block;	border-top: 1px solid #96CDF9;}

    .table-responsive > thead{ display: block; }
    .table-responsive > tbody{ display: block; }
    .table-responsive th{ display: block; }

    .table-responsive tr{ position: relative; display: block;}
    .table-responsive.table-responsive-unique tr{
        padding-right: 3rem;
    }
    .table-responsive tr:after{
        content: '';
        display: block;
        position: relative;
        height: 1rem;
        width: 100%;
        bottom: 0;	}

    .table-responsive tr:nth-of-type(even) td{background-color: #f9f9f9;	}
    .table-responsive tr:nth-of-type(odd)  td{background-color: #ffffff;}

    .table-responsive > thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;	}

    .table-responsive td{
        display: block;
        text-align: left !important;
        position: relative;
        padding: 1rem;
        padding-left: calc(35% + 1rem);
        overflow-x: auto;
        border: none !important;
        border-bottom: 1px solid #96CDF9 !important;
        line-height: 1.25;
    }

    .table-responsive td:before{
        content: attr(data-before);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        top: 0;
        left: 0;
        width: 35%;
        padding: 0.5rem 0rem;
        overflow-x: auto;
        background-color: $primary-color;
        color: white;
    }

    .table-responsive .responsive-td{
        position: absolute !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center;
        background-color: #fff !important;
        border-left: 1px solid $primary-color !important;
        height: calc(100% - 1rem) !important;
        top: 0 !important;
        right: 0 !important;
        z-index: 2 !important;
        padding: 0 1rem !important;
        &:before{
            content: none !important;
        }
        &:after{
            font-family: icomoon;
            content: '\e903';
            position: absolute;
            font-size: 1.2rem;
            color: $primary-color;
            top: 1rem;
            z-index: 2;
        }
    }
}
[v-cloak] {
    display: none!important;
}

