@import "functions";
@import "../base/variables";

@mixin fluid-font-size($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-value(calc(#{$max-font-size} - #{$min-font-size}))} * ((100vw - #{$min-vw}) / #{strip-value($max-vw - $min-vw)}));
      }
      @media (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}



@mixin fluid-type($properties, $min-vw, $max-vw, $min-value, $max-value) {
  & {
    @each $property in $properties {
      #{$property}: $min-value;
    }

    @media screen and (min-width: $min-vw) {
      @each $property in $properties {
        #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
    }

    @media screen and (min-width: $max-vw) {
      @each $property in $properties {
        #{$property}: $max-value;
      }
    }
  }
}


// Mixins FOR Screen Sizes
@mixin custom-screen-width-min($winW){
  @media (min-width: #{$winW}){
    @content
  }
}

@mixin custom-screen-width-max($winW){
  @media (max-width: #{$winW}){
    @content
  }
}

@mixin custom-screen-width-min-max($minW, $maxW){
  @media (min-width: #{$minW}) and (max-width: #{$maxW}){
    @content
  }
}


@mixin xsmall-screen-320{
  @media (min-width: $xs_screen_320_min + px ) and (max-width: $xs_screen_320_max + px){  @content  }
}

@mixin xsmall-screen-480{
  @media (min-width: $xs_screen_480_min + px ) and (max-width: $xs_screen_480_max + px){  @content  }
}


@mixin small-screen{
  @media (min-width: $sm_screen_min + px ) and (max-width: $sm_screen_max + px){  @content  }
}

@mixin medium-screen{
  @media (min-width: $md_screen_min + px ) and (max-width: $md_screen_max + px){  @content  }
}

@mixin large-screen{
  @media (min-width: $lg_screen_min + px ) and (max-width: $lg_screen_max + px){  @content  }
}

@mixin xlarge-screen{
  @media (min-width: $xl_screen_min + px ) and (max-width: $xl_screen_max + px){  @content  }
}

@mixin xxlarge-screen{
  @media (min-width: $xxl_screen_min + px ) and (max-width: $xxl_screen_max + px) {  @content  }
}


//----------------------
// MIN
//----------------------

@mixin xsmall-screen-320-min{
  @media (min-width: $xs_screen_320_min + px  ){  @content  }
}

@mixin xsmall-screen-480-min{
  @media (min-width: $xs_screen_480_min + px  ){  @content  }
}

@mixin small-screen-min{
  @media (min-width: $sm_screen_min + px ){  @content  }
}

@mixin medium-screen-min{
  @media (min-width: $md_screen_min + px ){  @content  }
}

@mixin large-screen-min{
  @media (min-width: $lg_screen_min + px ){  @content  }
}

@mixin xlarge-screen-min{
  @media (min-width: $xl_screen_min + px ){  @content  }
}

@mixin xxlarge-screen-min{
  @media (min-width: $xxl_screen_min + px ){  @content  }
}

//-------------------
// MAX
//---------------------

@mixin xxlarge-screen-max{
  @media (max-width: $xxl_screen_max + px) {  @content  }
}

@mixin xlarge-screen-max{
  @media (max-width: $xl_screen_max + px){  @content  }
}

@mixin large-screen-max{
  @media (max-width: $lg_screen_max + px){  @content  }
}

@mixin medium-screen-max{
  @media (max-width: $md_screen_max + px){  @content  }
}
@mixin orientation-landscape{
  @media (orientation: landscape){  @content  }
}

@mixin small-screen-max{
  @media (max-width: $sm_screen_max + px){  @content  }
}

@mixin xsmall-screen-480-max{
  @media (max-width: $xs_screen_480_max + px){  @content  }
}

@mixin xsmall-screen-320-max {
  @media (max-width: $xs_screen_320_max + px) { @content }
}
