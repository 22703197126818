#logout-btn-app {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-width: 0;
  border-top-width: 1px;

  span{
    padding-right: 1rem;
  }
  i{
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -0.5rem;
  }

  transform: translateX(-70%);

  transition: all 0.3s;

  &:hover {
    transform: translateX(0%);
  }

  @include medium-screen-max() {
    transform: translateX(0%);
    padding-top: .7rem;
    padding-bottom: .7rem;
  }

}
#fullscreen-btn-app {
  position: fixed;
  bottom: 4rem;
  left: 0rem;
  z-index: 2;
  border-radius: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-width: 0;
  border-top-width: 1px;

  span{
    padding-right: 1rem;
  }
  i{
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -0.5rem;
  }

  transform: translateX(-85%);

  transition: all 0.3s;

  &:hover {
    transform: translateX(0%);
  }

  @include medium-screen-max() {
    transform: translateX(0%);
    padding-top: .7rem;
    padding-bottom: .7rem;
  }

}

#unfullscreen-btn-app {
  position: fixed;
  bottom: 4rem;
  left: 0rem;
  z-index: 2;
  border-radius: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-width: 0;
  border-top-width: 1px;

  span{
    padding-right: 1rem;
  }
  i{
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -0.5rem;
  }

  transform: translateX(-70%);

  transition: all 0.3s;

  &:hover {
    transform: translateX(0%);
  }

  @include medium-screen-max() {
    transform: translateX(0%);
    padding-top: .7rem;
    padding-bottom: .7rem;
  }

}

.slide-title{
  font-size: 1.5rem;
  font-weight: bold;
  background-color: $body-color;
  color: $title-color;
  text-transform: uppercase;
  text-align: center;
  padding: 1rem 0;
  text-shadow: 0px 2px 3px gray;

  @include medium-screen-max(){
    color: white;
    background-color: $title-color;
    margin-bottom: 1rem;
    margin-left: -7%;
    margin-right: -7%;
    font-size: 1.25rem;
  }
}

.install-wrapper {
  display: none;
  position: fixed;
  left: 5%;
  bottom: 210px;
  z-index: 10;
}
#installBanner {
  position: absolute;
  width: 200px;
  height: 200px;
}.icon-close {
   position: absolute;
   z-index: 20;
   left: 210px;
   top: 0;
   height: 24px;
   width: 24px;
   cursor: pointer;
   -webkit-filter: invert(1);
   filter: invert(1);
 }

section{

  min-height: calc(100% - 1rem);
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0,0,0,0.4);

  @include medium-screen-max() {
    min-height: auto;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  >.tab-content {
    >.active {

      height: 100% ;
      overflow-y: auto;
      overflow-x: hidden;
      &#chat-app{
        overflow-y: hidden;
      }
    }
  }
}

.swiper-button-wrapper{
  position: absolute;
  bottom: 0rem;
  left: -1000px;
  z-index: 2;

  height: 4rem;

  background-color: $footer-color;
  text-align: center;

  @include medium-screen-max(){
    height: 3rem;
    left:0;
    right: 0;
  }
}

.swiper-pagination-bullet{
  width: .75rem;
  height: .75rem;

  &-active{
    background-color: #fff;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: relative;
  top:50%;
  left: auto;
  right: auto;
  bottom: auto;
  display: inline-block;
  color: #2f557c;
  background-color: #D9D9DB;
  padding: 0.5rem 1rem;
  margin-top: 0;
  width: auto;
  height: auto;
  transform: translateY(-50%);
  background-image: none;
  line-height: 1;
  font-size: 1.25rem;

  &.swiper-button-disabled{
    color: #7A8DA0;
    opacity: 1;
  }

  @include medium-screen-max(){
    display: none;
  }
}

$nav-bg : #455C7F;

.nav-tabs{
  background-color: #fff;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;
  border-bottom: 1px solid $primary-color;

  @include medium-screen-max() {

  }
}

.nav-tabs .nav-item{
  position: relative;
  margin-bottom: 0px;
  font-size: 0.85rem;

  @include medium-screen-max(){
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    font-size: 0.75rem;
    line-height: 0.75rem;
    .nav-link{
      width: 100%;
    }
  }

}

.nav-tabs .nav-link {
  position: relative;
  display: inline-block;
  line-height: 1;

  transition: background-color 0.5s;

  background-color: #fff;

  color: $nav-bg;

  border: none;
  border-radius: 0;

  padding: 1rem 0.5rem;
  margin: 0;
  z-index: 1;

  &:first-of-type.active{
    &:after {
      right: 100%;
      background: transparent !important;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0px;
    width: 1px;
    background: linear-gradient(180deg, transparent, $nav-bg 50%, transparent);
    height: 100%;
    z-index: 5;
  }

  &.active {
    background-color: $nav-bg;
    color: white;

    &:after {
      right: 100%;
      background: #fff;
    }
  }


}


span.phone, span.email, span.viber{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.75rem;
  i{
    color: $primary-color;
  }

}

.ticket-info{

  .ticket-fio-label,
  .ticket-status-label{
    display: inline-block;
    width: 40%;
    text-align: right;
  }

  .ticket-fio,
  .ticket-status{
    display: inline-block;
    margin-left: 1rem;
  }

}

.ticket-descr{
  border-bottom: 1px solid $separator-color;
  display: flex;
  padding-bottom: 0.5rem;
  h3{
    min-width: 60%;
    font-size: 2rem;
    align-self: center;
  }
  .wrapertime{
    min-width: 40%;
    align-self: center;
    text-align: center;
  }
  span{
    font-size: 1rem;
  }
}

.ticket-info{
  min-height: 6rem;
  overflow: auto;
}
.dropdown-menu {
  width: 100%;
  margin-top: 0;
}

.dropdown-settings{

  background-color: #fff;
  border-top: 1px solid $separator-color !important;
  border-bottom: 1px solid $separator-color !important;

  transition: background-color 0.4s;

  &:hover{
    background-color: $separator-color;
  }

  cursor: pointer;
  .settings{
    position: absolute;
    font-size: 1.7rem;
    margin-left: -2.25rem;
    margin-top: -.3rem;
  }
}
.badge.floating {
  background: red;
  cursor: inherit;
  position: absolute;
  right: 5px;
  top: -10px;
}
