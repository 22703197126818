
.datetime {
  color: #1b1b1b;
  border-bottom: 1px solid $separator-color;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.time, .date{
  position: relative;
  padding: 0.5rem 0rem;
  padding-left: .5rem;
  font-size: 1.5rem;
  z-index: 10;
}

.time span{
  position: relative;
  top: 0;
  left: 0;
  z-index: 5;
  font-size: 1.5rem;
  color: white;
  i{
    font-size: 1.25rem;
    margin-right: 1rem;
  }
}

.time:after{
  content: '';
  display: inline-block;
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  width: px-to-rem(170px);
  border-bottom: 70px solid $primary-color;
  border-right: 40px solid transparent;
}

.date{
  padding-right: .5rem;
  span{
    color: #9C9B9E;
    text-transform: capitalize;

    &.weekday{
      color: #5b4b53;
    }
  }
}

button.dropdown-toggle{
  width: 100%;
  line-height: 1;
  padding: 1rem 0;
  border: 0;
  color: #5b4b53;

  &:focus{
    background-color: #dee2e6;
    outline: none;
  }
}

.operators{
  color: #5b4b53;
  padding: 1rem;
  border-bottom: 1px solid $separator-color;
  font-weight: 500;
  font-size: 1.4rem;
}

.operator-actions{
  padding-top: 0.5rem;
  margin: 0 -10px;
  .button{
    height: 100%;
    font-size: 1.05rem;
    line-height: 1.2;

    @include medium-screen-max(){
      font-size: 1rem;
    }
  }
  .col{
    flex-basis: 50%;
    padding-left: .5rem;
    padding-right: 0.5rem;
    margin: 0.35rem 0 ;

    &:nth-of-type(even){
      padding-right: .75rem;
    }
    &:nth-of-type(odd){
      padding-left: .75rem;
    }
  }
}

#form-options{
  div{
    margin-bottom: 0.5rem;
  }
}
